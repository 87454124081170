<template>
  <div>
    <div class="coupon-item">
      <div class="coupon-rate">
        <div class="coupon-rate-num">{{item.HDJType==1?`$${(item.Maxmoney)}`:`${item.Percents}折`}}</div>
        <div class="coupon-rate-des">{{item.HDJType==1?`满$${(item.Orderprice)}使用`:`最高抵扣${(item.Maxmoney)}`}}</div>
      </div>
      <div class="coupon-detail">
        <div class="coupon-detail-title">
          {{item.TypeInfo==1?`通用`:item.TypeInfo==2?'专车':item.TypeInfo==3?'代驾':'跑腿'}}券
        </div>
        <div class="coupon-detail-date">
          {{item.Start.substr(0,10).replace(/-/g,'.')}}-{{item.End.substr(0,10).replace(/-/g,'.')}}
        </div>
        <div class="coupon-detail-rule">
          {{item.HDJType==1?`满$${(item.Orderprice)}使用`:`最高抵扣${item.Maxmoney}`}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Popup } from 'vant'
// import { mapState } from 'vuex';
// import { getStore } from '../../lib/storage.js'
// import { exchangeRate } from '@/mixins/exchangeRate.js'

export default {
  name: 'raccoupon',
  // mixins:[exchangeRate],
  components: {
    // [Popup.name]: Popup
  },
  props: ['item','rateNum'],
  data () {
    return {
    }
  },
  computed: {


  },
  methods: {
    
  },
  mounted(){
    // this.exchangeRateHandler()
  }

}
</script>

<style scoped lang="less">
@import '../../styles/mixin.less';

.coupon {
  &-item {
    display: flex;
    flex: 1;
    align-items: center;
    width: 640px;
    height: 240px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
  }

  &-rate {
    align-items: center;
    // justify-content: center;
    width: 216px;
    height: 240px;
    background: rgba(4, 186, 47, 1);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
    border-radius: 20px 0px 0px 20px;
    color: rgba(255, 255, 255, 1);
    text-align: center;

    &-num {
      margin: 60px 0 18px;
      font-size: 52px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      line-height: 74px;
    }
  }

  &-detail {
    padding-left: 30px;
    color: rgba(153, 153, 153, 1);

    &-title {
      margin-bottom: 8px;
      font-size: 48px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(34, 34, 34, 1);
      line-height: 66px;
    }

    &-date {
      font-size: 28px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 40px;
    }

    &-rule {
      font-size: 28px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 40px;
    }
  }
}
</style>
