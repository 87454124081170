// import { getExchangeRate } from '@/service/';

export const showorder = {
  data() {
    return {
      showOrder: false,
    };
  },
  methods: {
    goToOrder() {
      this.showOrder = true;
    },
  },
};
