export const timePicker = {
    data() {
        return {
            timeList: [],
            timelist: {},
        };
    },
    computed: {
        // timeList: {

        // }
    },
    methods: {
        // 格式化时间 年-月-日
        getDate(num, str) {
            let date = new Date();
            let nowTime = date.getTime();
            let ms = 24 * 3600 * 1000 * num;
            date.setTime(parseInt(nowTime + ms));
            let nowMonth = date.getMonth() + 1;
            let strDate = date.getDate();
            // 对月份进行处理，1-9月在前面添加一个“0”
            if (nowMonth >= 1 && nowMonth <= 9) {
                nowMonth = '0' + nowMonth;
            }
            // 对日进行处理，1-9日在前面添加一个“0”
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }

            let nowDate = date.getFullYear() + str + nowMonth + str + strDate;

            return nowDate;
        },
        getBeforeDate(n, str) {
            var date = new Date();
            var year, month, day;
            date.setDate(date.getDate() - n);
            year = date.getFullYear();
            month = date.getMonth() + 1;
            day = date.getDate();
            let s =
                year +
                str +
                (month < 10 ? '0' + month : month) +
                str +
                (day < 10 ? '0' + day : day);
            return s;
        },
        // 获取当前时间前后时间并格式化
        getDateList(pre, num) {
            let arr = [];
            for (let i = pre; i > 0; i--) {
                arr.push(this.getBeforeDate(i, '-'));
            }
            for (let i = 0; i < num; i++) {
                arr.push(this.getDate(i, '-'));
            }
            return arr;
        },
        // 初始化时间数据列表
        getTimeList(pre, num) {
            let timelist = {};
            let dateList = this.getDateList(pre, num);
            let hoursList = [];
            let minuteList = [];
            let date = new Date();
            // console.log(dateList)
            // 小时列表
            for (let i = 0; i < 24; i++) {
                if (i < 10) {
                    hoursList.push(`0${i}`);
                } else {
                    hoursList.push(`${i}`);
                }
            }
            // 每隔10分钟列表
            for (let j = 0; j < 6; j++) {
                minuteList.push(`${j}0`);
            }

            for (let k = 0; k < dateList.length; k++) {
                // if (k == 0) {
                //   // 获取当前小时
                //   // let currentHours = date.getHours();
                //   // 获取当前分钟
                //   // let currentMinutes = date.getMinutes();

                //   // let todayHoursList = hoursList.filter(
                //   //   hour => parseInt(hour) > currentHours,
                //   // );
                //   // let curHourMinutesList = minuteList.filter(
                //   //   minute => parseInt(minute) > currentMinutes,
                //   // );

                //   timelist[dateList[k]] = todayHoursList;

                //   // if()
                // } else {
                timelist[dateList[k]] = hoursList;
                // }
            }
            // console.log(timelist);
            // timelist = {};
            this.timelist = timelist;
            // // 当前日期
            // let nowDate = this.getDate(0, '-');
            // // 当前小时
            // let currentHours = date.getHours();
            // // 当前分钟
            // let currentMinutes = date.getMinutes()+10;
            let curMillions = date.getTime();
            let nextMillions = new Date(curMillions + 10 * 60 * 1000);

            let nextYear = nextMillions.getFullYear();
            let nextMonth = nextMillions.getMonth() + 1;
            let nextDay = nextMillions.getDate();

            let nextDate =
                nextYear +
                '-' +
                (nextMonth < 10 ? '0' + nextMonth : nextMonth) +
                '-' +
                (nextDay < 10 ? '0' + nextDay : nextDay);

            let nextHour =
                nextMillions.getHours() < 10 ?
                `0${nextMillions.getHours()}` :
                nextMillions.getHours();

            let nextMinutes =
                nextMillions.getMinutes() - (nextMillions.getMinutes() % 10);

            let defaultDate = Object.keys(timelist).findIndex(
                item => item == nextDate,
            );

            let defaultHour = timelist[dateList[defaultDate]].findIndex(
                item => item == nextHour,
            );
            let defaultMinutes = minuteList.findIndex(item => item == nextMinutes);

            this.timeList = [{
                    values: Object.keys(timelist),
                    className: 'column1',
                    defaultIndex: defaultDate,
                },
                {
                    values: this.timelist[dateList[0]],
                    className: 'column2',
                    defaultIndex: defaultHour,
                },
                {
                    values: minuteList,
                    className: 'column3',
                    defaultIndex: defaultMinutes,
                },
            ];
            // if (this.timeList[0].values.indexOf('2020-03-08') == -1) {
            //     console.log("不包含子字符串")
            //     let index = this.timeList[0].values.indexOf('2020-03-07')
            //     console.log(index)
            //     this.timeList[0].values.splice(1, 0, "2020-03-08");

            // } else {

            //     console.log("包含子字符串")
            // }

            // console.log(this.timeList[0].values)
        },
        formateDate(date) {
            let year = date.getFullYear();
            let nowMonth = date.getMonth() + 1;
            let strDate = date.getDate();
            let nowHours = date.getHours();
            let nowMinutes = date.getMinutes();
            let nowSeconds = date.getSeconds();
            // 对月份进行处理，1-9月在前面添加一个“0”
            if (nowMonth >= 1 && nowMonth <= 9) {
                nowMonth = '0' + nowMonth;
            }
            // 对日进行处理，1-9日在前面添加一个“0”
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            nowHours = nowHours < 10 ? `0${nowHours}` : nowHours;
            nowMinutes = nowMinutes < 10 ? `0${nowMinutes}` : nowMinutes;
            nowSeconds = nowSeconds < 10 ? `0${nowSeconds}` : nowSeconds;

            let nowDate = `${year}-${nowMonth}-${strDate} ${nowHours}:${nowMinutes}:${nowSeconds}`;

            return nowDate;
        },
    },
};